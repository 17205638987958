import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dialog, Button, Icon, H1, P1R } from 'src/components';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';

type Props = {
  onClose: () => void;
};

const ContinueWithClearPopup = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            padding: '34px 0 34px 0',
            width: '314px',
            gap: '34px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <H1 sx={{ fontSize: '44px' }}>🥳</H1>

          <H1>{t('clearPopup.title')}</H1>

          <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column' }}>
            <P1R sx={{ width: '100%', textAlign: 'center' }}>{t('clearPopup.verifyIdentity')}</P1R>
            <P1R sx={{ width: '100%', textAlign: 'center' }}>{t('clearPopup.clickBelow')}</P1R>
          </Box>

          <Box
            sx={{
              padding: '16px',
              gap: '16px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: themeColors.lightYellow,
            }}
          >
            <Box>
              <Icon name="attention" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <P1R sx={{ fontWeight: '500' }}>{t('clearPopup.important')}</P1R>
              <P1R sx={{ fontWeight: '500' }}>{t('clearPopup.importantMessage')}</P1R>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Button
              primary={false}
              onClick={() => navigate(PATHS.CLEARME_AUTH, { state: { initClear: true } })}
              sx={{ width: '100%', flexGrow: 1, textTransform: 'none', ':hover img': { filter: 'invert(100%)' } }}
            >
              <Icon name="clearmeIcon" sx={{ mr: 2 }} />
              {t('clearPopup.continue')}
            </Button>
          </Box>

          <P1R
            onClick={onClose}
            sx={{
              ml: 0.5,
              fontSize: '14px',
              textDecoration: 'none',
              color: themeColors.blue,
              textTransform: 'capitalize',
              cursor: 'pointer',
            }}
          >
            {t('clearPopup.later')}
          </P1R>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ContinueWithClearPopup;
